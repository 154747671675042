import { useTranslate } from '/machinery/I18n'

export function useNormalizedJobInfoTags({
  salaryScale = undefined,
  education = undefined,
  experienceLevel = undefined,
  employmentType = undefined,
  jobArea = undefined,
  hoursPerWeek = undefined,
  canBeReferred = undefined
}) {
  const { __ } = useTranslate()

  return React.useMemo(
    () => {
      const salaryScaleTag = { label: salaryScale?.length && salaryScale?.map(x => x.name).join('/'), colorScheme: 'white' }
      const educationLevelsTag = { label: education?.length && education.map(x => x.label).join('/'), colorScheme: 'white' }
      const experienceLevelTag = { label: experienceLevel?.length && experienceLevel.map(x => x.label).join('/'), colorScheme: 'white' }
      const employmentTypeTag = { label: (employmentType.id && employmentType.id !== 'other' && employmentType.id !== 'permanent') ? __`contract-type-${employmentType.id}` : null, colorScheme: 'white' }
      const jobAreaTag = { label: jobArea?.name, colorScheme: 'white' }
      const hoursPerWeekTag = { label: hoursPerWeek.label && `${hoursPerWeek.label} ${__`hours`}`, colorScheme: 'white' }
      const referAFriendTag = { label: canBeReferred ? __`refer-a-friend` : null, colorScheme: 'darkBlue' }

      const tags = [
        salaryScaleTag,
        educationLevelsTag,
        experienceLevelTag,
        employmentTypeTag,
        jobAreaTag,
        hoursPerWeekTag,
        referAFriendTag
      ]

      return tags
    },
    [salaryScale, education, employmentType, experienceLevel, jobArea, hoursPerWeek, canBeReferred, __]
  )
}
